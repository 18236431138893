.loader {
    text-align: center !important;
    background: #feb700 !important;
    height: 100vh !important;
    display:flex;
    align-items: center;
    justify-content: center;
}

.loader p {
    font-size: 20px;
    margin-top:10px;
}