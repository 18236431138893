@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');

:root {
  --Rubik: "Rubik", sans-serif;
  --Sora: "Sora", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 100%;
}

body {
  font-size: 16px;
  line-height: 1.6;
  color: #1C1C1C;
  font-family: "Sora", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

a {
  outline: none;
  display: block;
}

p {
  color: #1C1C1C;
  font-weight: 400;
}

img {
  max-width: 100%;
}

a,
a:hover {
  text-decoration: none;
  transition: all 0.3s;
}

button,
select {
  outline: none;
  transition: all 0.3s;
}

ul,
ol {
  padding: 0px;
  margin: 0px;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0 0 15px;
}

a,
button {
  text-decoration: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.container {
  max-width: 1400px;
  width: 96%;
}

.header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}

.header-top {
  padding: 20px 0;
  margin: 0;
  background: #0C0934;
  transition: 0.5s;
}

.header-top-in {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
}

.left-col {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
}

.right-col ul {
  display: flex;
  column-gap: 20px;
}

.header-top-in a {
  color: #fff;
  font-weight: 300;
}

.left-col span {
  background: #24215A;
  width: 24px;
  height: 24px;
  display: inline-block;
  color: #FEB700;
  font-size: 12px;
  border-radius: 50%;
  text-align: center;
  line-height: 24px;
  margin-right: 3px;
}

.header-mid {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0;
  transition: 0.5s;
}

/* normal menu css */

.main_menu>ul>li {
  display: inline-block;
  position: relative;
  margin: 0 -2px;
}

.main_menu ul li {
  position: relative;
  margin: 0 10px;
}

.main_menu ul li a {
  font-size: 16px;
  color: #fff;
  padding: 18px 15px;
  display: block;
  font-weight: 400;
}

/* .main_menu ul li .active,
.main_menu ul li:hover>a {
  color: #0085FF;
} */

/* Normal Dropdown menu */
.main_menu ul li ul {
  width: 200px;
  background: #264795;
  transition: 0.5s;
  box-shadow: 0px 0 8px 0px rgb(255 255 255 / 20%);
  padding: 5px 0;
}

.main_menu ul li ul li a {
  padding: 5px;
  font-size: 15px;
}

.main_menu ul li ul li a i {
  float: right;
}

.main_menu ul li ul li ul {
  left: 100%;
  top: 0;
}

/* mega menu css */
.mega_menu_dropdown {
  position: static !important;
}

.mega_menu {
  left: 0;
  right: 0;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  transition: 0.5s;
}

.mega_menu_item {
  width: 25%;
  padding: 30px 20px;
}

.main_menu ul li .mega_menu_item a {
  padding: 10px 0;
}

.main_menu ul li .mega_menu_item a:hover {
  color: var(--hover-color);
}

.mega_menu_item h3 {
  margin-bottom: 15px;
  font-size: 22px;
}

.mega_menu_item img {
  width: 100%;
}

/* demo_2 css */
.mega_menu_demo_2 .mega_menu {
  left: 50%;
  transform: translateX(-50%);
  width: 1140px;
}

.mobile_btn {
  display: none;
}

/* responsive css */
/* @media (min-width: 992px) and (max-width: 1199.98px) {
  .mega_menu_demo_2 .mega_menu {
    width: 940px;
  }
  .main_menu ul li ul {
    width: 150px;
  }
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .container {
    width: 720px;
  }
  .mega_menu_demo_2 .mega_menu {
    width: 700px;
  }
  .main_menu ul li a {
    font-size: 15px;
    padding: 20px 16px;
  }
  .main_menu ul li ul {
    width: 150px;
  }
} */
@media (min-width: 992px) {
  .main_menu ul li ul {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    margin-top: 5px;
  }

  .main_menu ul li .mega_menu {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    margin-top: 0;
  }

  .main_menu ul li:hover>ul {
    visibility: visible;
    opacity: 1;
    margin-top: 0px;
    z-index: 99;
  }

  .main_menu ul li:hover>.mega_menu {
    visibility: visible;
    opacity: 1;
    margin-top: 0;
    z-index: 99;
  }
}

@media (max-width: 991px) {

  .mega_menu_demo_2 .mega_menu,
  .container {
    width: 100%;
  }

  nav {
    padding: 15px;
  }

  .mobile_btn {
    cursor: pointer;
    display: block;
    color: #fff;
    font-size: 24px;
    position: absolute;
    right: 0;
    top: 18px;
  }

  .mobile_btn .fa-xmark.fa-xl {
    font-size: 26px;
  }

  .main_menu {
    display: none;
    position: absolute;
    left: -26px;
    background: #180b39;
    top: 100%;
    right: -26px;
    padding: 0 20px;
  }

  .main_menu ul li {
    display: block;
  }

  .main_menu ul li a i {
    float: right;
  }

  .main_menu ul li a {
    border-bottom: 1px solid #ddd;
  }

  .main_menu ul li ul {
    width: 100%;
  }

  .main_menu ul li ul li ul {
    left: 0;
    top: auto;
  }

  .mega_menu .mega_menu_item {
    width: 50%;
  }

  .main_menu ul li ul {
    display: none;
    transition: none;
  }

  .main_menu ul li .mega_menu {
    display: none;
    transition: none;
  }

  .mega_menu_demo_2 .mega_menu {
    transform: translateX(0);
  }
}

@media (max-width: 767px) {
  .mobile_btn {
    top: 14px;
    font-size: 22px;
  }
}

/* ----------------menu css end----------- */
.right-col {
  display: flex;
  column-gap: 20px;
}

.sec {
  padding: 90px 0;
}


.footer {
  background: #1C1C1C;
  padding-top: 50px;
}

.footer-left p {
  color: #fff;
  max-width: 350px;
  margin: 15px 0;
  font-size: 16px;
  font-weight: 300;
}

ul.social-link {
  display: flex;
  column-gap: 12px;
}

ul.social-link a {
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  color: #FEB700;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  font-size: 16px;
  border: 1px solid #ccc;
}

.footer h4 {
  font-size: 18px;
  color: #FFBF41;
  font-weight: 600;
  margin: 0 0 15px;
}

ul.footer-nav {
  font-size: 14px;
  color: #fff;
}

ul.footer-nav a {
  font-size: 15px;
  color: #fff;
  line-height: 2;
  font-weight: 300;
}

.footer-bottom {
  padding: 5px 0;
  margin-top: 30px;
}

.footer-bottom p {
  margin: 0;
  color: #fff;
  text-align: center;
  border-top: 1px solid #5D5FAC;
  padding: 20px 0;
  font-weight: 300;
  font-size: 15px;
}

.custom-btn {
  color: #fff;
  background: #0085FF;
  padding: 8px 20px;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  display: inline-block;
  border: 2px solid #0085FF;
}

.custom-btn:hover,
.custom-btn-fill {
  color: #fff;
  background: #FEB700;
  border-color: #FEB700;
}

.main_menu ul li a:hover {
  color: #FFBF41;
}

ul.footer-nav a:hover {
  color: #FFBF41;
}

ul.social-link a:hover {
  background: #FFBF41;
  border-color: #FFBF41;
  color: #fff;
}

.mega_menu_item h3 {
  color: #000;
}

ul.footer-nav li i {
  margin-right: 3px;
  color: #FFBF41;
}

.banner-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.banner-cl {
  width: 50%;
  padding: 180px 0;
  position: relative;
  z-index: 1;
}

.banner-cr {
  width: 50%;
  position: absolute;
  right: 0;
  bottom: 0;
  top: 210px;
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 26% 100%);
  overflow: hidden;
}

.banner-cr img {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0 0 0 0;
}

.bn-sub {
  color: #00FEA3;
  text-transform: uppercase;
}

.banner-cl h1 {
  font-size: 60px;
  color: #fff;
  font-weight: 700;
  margin: 10px 0 20px;
}

.banner-cl h1 span {
  color: #FEB700;
}

.banner-cl p {
  font-size: 18px;
  color: #fff;
  font-weight: 300;
  margin: 0 0 25px;
}

.banner-cl .custom-btn {
  text-transform: uppercase;
  font-size: 16px;
  margin-right: 15px;
  letter-spacing: 1px;
}

/* .bor-btn {
  background: transparent;
  border: 2px solid #0085FF;
  color: #fff;
} */

.bor-btn:hover {
  background: #FEB700;
  border: 2px solid #FEB700;
  color: #fff;
}

.header-top-in a:hover {
  color: #f9b700;
}

.header.sticky {
  background: linear-gradient(135deg, rgba(24, 6, 51, 1) 24%, rgba(30, 73, 130, 1) 100%);
}

.header.sticky .header-mid {
  padding: 15px 0;
}

.logo-col img {
  transition: 0.5s;
  width: 215px;
}

.header.sticky .logo-col img {
  width: 170px;
  transition: 0.5s;
}

.dark-bg1 {
  background: #0A0C33;
  position: relative;
  overflow: hidden;
}
.dark-bg1 p,
.dark-bg2 p,
.dark-bg1 ,
.dark-bg2  {
  color: #B3B3B3;
}

.dark-bg1 .container {
  position: relative;
  z-index: 1;
}

.sec-head {
  max-width: 800px;
  margin: 0 auto 45px;
  text-align: center;
}

.sec-head h2 {
  font-size: 40px;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
  margin: 0 0 10px;
}

.sec-head h2 span {
  color: #FEB700;
}

.sec-head p {
  color: #EEEEEE;
  font-size: 19px;
  margin: 0;
  opacity: 60%;
}

.service-box {
  background: #070820;
  text-align: center;
  padding: 30px;
  margin: 30px 5px 0;
  border-radius: 8px;
  box-shadow: 0 0 14px rgb(255 255 255 / 5%);
  border: 2px solid #070820;
  cursor: pointer;
}

.srv-icon {
  display: block;
  margin: 0 0 20px;
}

.service-box h4 {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  margin: 0 0 20px;
}

.service-box p {
  color: #B3B3B3;
  font-weight: 300;
  margin: 0 25px;
  line-height: 2;
}

.service-box:hover {
  border: 2px solid #0085FF;
  box-shadow: 0 0 24px rgb(255 255 255 / 25%);
}

.service-box:hover h4 {
  color: #0085FF;
}

.dark-bg2 {
  background: #10092C;
  position: relative;
  overflow: hidden;
}

.methode-content .sec-head {
  text-align: left;
  margin: 0 0 40px;
}

.methode-content ul li {
  margin-top: 25px;
}

.methode-content ul li a {
  background: #16184B;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  column-gap: 20px;
  justify-content: space-between;
  font-size: 18px;
  border-radius: 6px;
}

.methode-content ul li a:hover {
  box-shadow: 0 0 8px rgb(255 255 255 /15%);
  background: #101135;
}

.methode-content ul li a span {
  width: calc(100% - 65px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 20px;
}

.methode-content ul li a span small {
  color: #B3B3B3;
  font-weight: 300;
  display: block;
  font-size: 16px;
}

.methode-content ul li a i {
  color: #0085FF;
  font-size: 22px;
}

.methode-content ul li a .fa-arrow-right {
  width: 32px;
  height: 32px;
  background: #090B38;
  text-align: center;
  line-height: 32px;
  color: #fff;
  border-radius: 50%;
  font-size: 16px;
}

.methode-img {
  padding: 40px 0;
}

.count-sec {
  background: url(../images/img-bg.jpg) no-repeat center center;
  background-size: cover;
  position: relative;
}

.count-sec:after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #212254;
  opacity: 0.8;
}

.count-row {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 30px;
}

.count-col {
  width: 20%;
  text-align: center;
}

.count-col p {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  margin: 0;
}

.count-lable {
  font-size: 15px;
  color: #fff;
  font-weight: 300;
}

.count-icon {
  color: #FEB700;
  font-size: 70px;
}

.has_dropdown i {
  font-size: 14px;
  margin-left: 5px;
}

.pricing-box h3 {
  font-size: 24px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
}

.pricing-box p {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
}

.pricing-box p span {
  color: #FEB700;
  font-size: 24px;
}

.pricing-box {
  background: #131547;
  border-radius: 6px;
  padding: 0 0 25px;
  margin: 0 5px;
  border: 2px solid #131547;
  height: 100%;
}

.c-m-s {
  display: block;
  min-height: 70px;
}

.pricing-box ul li {
  font-size: 17px;
  color: #fff;
  position: relative;
  padding: 10px 10px;
  min-height: 45px;
  line-height: 1.4;
}

.over-line {
  text-decoration: line-through;
  display: block;
  min-height: 82px;
}

.pricing-box ul li i {
  color: #FEB700;
  font-size: 20px;
}

.pricing-box ul li i.fa-x {
  color: #DA2C06;
}

.pricing-box ul {
  border-top: 1px solid #3F4181;
  margin: 0 0 15px;
  padding: 10px;
}

.std-plan {
  margin: 15px 10px;
  border: 2px solid #0085FF;
}

.pricing-box .custom-btn {
  display: block;
  text-align: center;
  color: #0085FF;
  padding: 10px 20px;
  margin: 0 20px;
}

.yel-b {
  color: #FEB700;
  font-weight: 700;
  font-size: 18px;
}

.pricing-box .custom-btn:hover {
  color: #fff;
}

.std-plan .custom-btn {
  color: #fff;
}

.std-plan ul {
  margin-bottom: 35px;
  margin-top: 35px;
}

.pricing-sec .sec-head {
  margin-bottom: 50px;
}

.faq-img {
  text-align: center;
}

.cus-accordion .cus-accordion-item {
  border-bottom: 1px solid #3F4181;
}


.cus-accordion button {
  position: relative;
  display: block;
  text-align: left;
  width: 100%;
  padding: 1em 0;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  border: none;
  background: none;
  outline: none;
}

.cus-accordion button:hover {
  cursor: pointer;
  color: #FEB700;
}


.cus-accordion button .cus-accordion-title {
  padding: 0px 30px 0px 0;
}

.cus-accordion button .icon {
  display: inline-block;
  position: absolute;
  top: 18px;
  right: 0;
  width: 22px;
  height: 22px;
  border: 1px solid;
  border-radius: 22px;
}

.cus-accordion button .icon::before {
  display: block;
  position: absolute;
  content: '';
  top: 9px;
  left: 5px;
  width: 10px;
  height: 2px;
  background: currentColor;
}

.cus-accordion button .icon::after {
  display: block;
  position: absolute;
  content: '';
  top: 5px;
  left: 9px;
  width: 2px;
  height: 10px;
  background: currentColor;
}

.cus-accordion button[aria-expanded='true'] .icon::after {
  width: 0;
}

.cus-accordion .cus-accordion-content {
  max-height: 0;
  overflow: hidden;
  transition: 0.3s;
}

.cus-accordion .cus-accordion-content p {
  font-weight: 300;
  color: #fff;
}

.faq-img {
  text-align: center;
  padding: 30px 0;
}

.cus-accordion button.active .icon {
  color: #0084FF;
}

.cus-accordion {
  padding-right: 25px;
}

.test-box {
  background: #131547;
  border-radius: 8px;
  padding: 30px;
}

.test-box p {
  color: #B3B3B3;
  font-weight: 300;
}

.test-bot {
  display: flex;
  align-items: center;
  column-gap: 25px;
  justify-content: space-between;
  border-top: 1px solid #3F4181;
  padding-top: 20px;
  margin-top: 25px;
}

.test-col {
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.test-img {
  width: 65px;
  height: 65px;
  overflow: hidden;
  border-radius: 50%;
}

.test-col p {
  color: #fff;
  font-weight: 500;
  margin: 0;
}

.test-col p span {
  color: #B3B3B3;
  font-weight: 300;
  display: block;
  font-size: 15px;
}

.slider-sec .sec-head {
  margin: 0 0 40px;
  text-align: left;
}

.test-carousel .owl-nav {
  position: absolute;
  right: 0;
  top: -60px;
  margin: 0;
}

.test-carousel .owl-nav button {
  color: #fff !important;
  width: 36px;
  height: 36px;
  border: 1px solid #0084FF !important;
  border-radius: 50% !important;
}

.test-carousel .owl-nav button:hover {
  background: #0084FF !important;
}

.art-box {
  background: #1B1D50;
  padding: 15px;
  border-radius: 8px;
}

.art-img {
  border-radius: 8px;
  overflow: hidden;
}

.art-dl {
  padding: 15px 0 5px;
}

.art-top {
  display: flex;
  column-gap: 15px;
  align-items: center;
  justify-content: space-between;
}

.art-stock {
  background: #2B2D67;
  color: #fff;
  font-size: 15px;
  padding: 4px 10px;
  border-radius: 4px;
}

.art-date {
  color: #fff;
  font-size: 15px;
}

.art-dl h4 {
  color: #fff;
  font-size: 20px;
}

.art-dl p {
  color: #B3B3B3;
  font-weight: 300;
}

.learn-more {
  color: #FEB700;
  font-size: 18px;
  text-decoration: underline;
  text-underline-offset: 5px;
}

.art-carousel .owl-nav button {
  position: absolute;
  top: 50%;
  margin-top: -10px !important;
  color: #fff !important;
  width: 36px;
  height: 36px;
  border: 1px solid #0084FF !important;
  border-radius: 50% !important;
  opacity: 1;
  background: #1c1d50 !important
}

.art-carousel .owl-nav .owl-next {
  right: -25px;
}

.art-carousel .owl-nav .owl-prev {
  left: -25px;
  ;
}

.art-carousel .owl-nav button:hover {
  background: #0084FF !important;
}

.subs-box {
  background: linear-gradient(135deg, rgba(17, 42, 129, 1) 0%, rgba(37, 34, 194, 1) 35%, rgba(35, 18, 136, 1) 100%);
  border-radius: 24px;
  padding: 20px 100px;
  margin-bottom: 100px;
  margin-top: 50px;
}

.subs-form h3 {
  font-size: 40px;
  color: #fff;
  font-weight: 600;
}

.subs-form p {
  font-size: 18px;
  color: #fff;
}

.subs-f-row {
  background: #fff;
  border-radius: 8px;
  display: flex;
}

.subs-f-row button {
  background: #0084FF;
  color: #fff;
  border: none;
  outline: none;
  width: 170px;
  height: 50px;
  border-radius: 0 6px 6px 0;
}

.subs-f-row input[type="text"] {
  margin-left: 15px;
  border: none;
  outline: none;
  width: calc(100% - 185px);
  padding: 5px;
}

.dark-bg1:after {
  content: "";
  position: absolute;
  background: radial-gradient(ellipse at center, rgba(44, 50, 159, 1) 0%, rgba(255, 255, 255, 0) 100%);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  top: 20px;
  right: -80px;
  filter: blur(20px);
  opacity: 0.4;
}

.dark-bg1:before {
  content: "";
  position: absolute;
  background: radial-gradient(ellipse at center, rgba(44, 50, 159, 1) 0%, rgba(255, 255, 255, 0) 100%);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  bottom: 20px;
  left: -80px;
  filter: blur(20px);
  opacity: 0.4;
}

.dark-bg2:after {
  content: "";
  position: absolute;
  background: radial-gradient(ellipse at center, #43467E 0%, rgba(255, 255, 255, 0) 100%);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  top: 20px;
  right: -80px;
  filter: blur(20px);
  opacity: 0.3;
}

.dark-bg2:before {
  content: "";
  position: absolute;
  background: radial-gradient(ellipse at center, #43467E 0%, rgba(255, 255, 255, 0) 100%);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  bottom: 20px;
  left: -80px;
  filter: blur(20px);
  opacity: 0.3;
}

.q-img-r {
  position: absolute;
  right: 4%;
  bottom: 25%;
}

.q-img-l {
  position: absolute;
  left: 2%;
  top: 7%;
}

.methode-sec .q-img-l {
  left: auto;
  right: 3%;
  top: 13%;
}

.methode-sec .q-img-r {
  left: auto;
  left: 3%;
  bottom: 10%;
}

.pricing-sec .q-img-r {
  bottom: 10%;
}

.faq-sec .q-img-l {
  top: 15%;
  left: 3%;
}

.slider-sec .q-img-l {
  top: 15%;
}

.slider-sec .q-img-r {
  bottom: 10%;
}

.slider-sec1 .q-img-r {
  bottom: 10%;
  right: 5%;
}

.footer .q-img-l {
  left: 3%;
  top: 10%;
}

.footer .q-img-r {
  bottom: 5%;
  right: 7%;
}

.footer:before {
  bottom: 30%;
  left: -70px;
}

.footer:after {
  right: -70px;
  bottom: 10%;
  top: auto;
}

.slider-sec1:after {
  top: 17%;
}

.slider-sec1:before {
  bottom: 17%;
}

.slider-sec:after {
  top: 17%;
}

.faq-sec:before {
  bottom: 20%;
}

.faq-sec:after {
  bottom: 5%;
  top: auto;
}

.methode-sec:after {
  top: auto;
  bottom: 3%;
  right: 0;
}

.methode-sec:before {
  top: 7%;
  bottom: auto;
}

.service-sec:before {
  bottom: 40%;
  left: 8%;
}

.dark-bg2 .container {
  position: relative;
  z-index: 1;
}

.header.sticky .header-top {
  padding: 13px 0;
}

.mt-img-left {
  margin-right: 50px;
}

.mt-img-left img {
  width: 100%;
  border-radius: 0 60px 0 0;
}

.mt-img-right {
  margin-left: 50px;
}

.mt-img-right img {
  width: 100%;
  border-radius: 60px 0 0 0;
}

.bro-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #19103E;
  height: 100px;
  width: 250px;
  border-radius: 6px;
}

.bro-btn-row {
  display: flex;
  column-gap: 15px;
  margin: 0 0 35px;
}

.methode-content p {
  color: #B3B3B3;
  font-size: 18px;
  margin: 0 0 35px;
}

.subs-f-row button:hover {
  background: #f9b700;
}

.q-img-r {
  animation: float 6s ease-in-out infinite;
}

.q-img-l {
  animation: float1 6s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-50px);
  }

  100% {
    transform: translatey(0px);
  }
}

@keyframes float1 {
  0% {
    transform: translatex(0px);
  }

  50% {
    transform: translatex(-50px);
  }

  100% {
    transform: translatex(0px);
  }
}


.dark-bg1:before {
  content: "";
  position: absolute;
  background: radial-gradient(ellipse at center, rgba(44, 50, 159, 1) 0%, rgba(255, 255, 255, 0) 100%);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  bottom: 20px;
  left: -80px;
  filter: blur(20px);
  opacity: 0.4;
}

.dark-bg1:after {
  content: "";
  position: absolute;
  background: radial-gradient(ellipse at center, rgba(44, 50, 159, 1) 0%, rgba(255, 255, 255, 0) 100%);
  width: 200px;
  height: 200px;
  border-radius: 50%;
  top: 20px;
  right: -80px;
  filter: blur(20px);
  opacity: 0.4;
}

.banner-sec .container {
  position: static;
}

.banner-sec:after {
  left: 0;
  top: 155px;
}

.banner-sec:before {
  left: 30px;
  bottom: 25px;
}

.banner-sec {
  padding-top: 210px;
  padding-bottom: 0;
  background: linear-gradient(135deg, rgba(24, 6, 51, 1) 24%, rgba(30, 73, 130, 1) 100%);
  position: relative;
}

.pric-row {
  display: flex;
  overflow: auto;
  width: 100%;
}

.pric-col-md {
  width: 20%;
}

.pric-col-sm {
  width: 5%;
}

.pricing-box .prc-head {
  margin: 0;
  padding: 20px;
  min-height: 70px;
}

.pric-col-sm ul {
  border: none;
  text-align: center;
}

.pric-col-sm ul li {
  text-align: center;
}

.ds-mail {
  display: block;
}

.art-carousel .owl-dots {
  display: none;
}

.inner-banner {
  position: relative;
  z-index: 1;
  background: linear-gradient(180deg, #060b7a 0%, #040f52 100%);
}

.inner-banner .row {
  min-height: 400px;
}

.inner-banner h1 {
  font-size: 60px;
  color: #ffffff;
  font-weight: 700;
  line-height: 72px;
  text-align: left;
  font-family: var(--Rubik);
  text-align: center;
}

.bg-fixed {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
}

.header-space {
  height: 157px;
  background: linear-gradient(135deg, rgba(24, 6, 51, 1) 24%, rgba(30, 73, 130, 1) 100%);
}

.header.sticky~.header-space {
  height: 143px;
}

.inner-banner .bg-fixed {
  opacity: 0.44;
}

.inner-banner p {
  color: #FEB700;
  text-align: center;
  font-family: Sora;
  font-size: 16px;
  font-weight: 600;
  line-height: 22.4px;
  letter-spacing: 0.1em;
  margin: 0 0 0px;
}

.inner-banner p.breadcrumbs {
  font-family: Sora;
  font-size: 16px;
  font-weight: 300;
  line-height: 19.2px;
  text-align: left;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  text-align: center;
  bottom: 10px;
  color: #EEEEEE;
}

.pricing-sec.psec-inner .sec-head {

  margin-left: 0;

  text-align: left;
}

.pricing-sec.psec-inner .bg-fixed {

  z-index: 0;

  object-fit: contain;
}

.price-tabs .pt-list {
  display: flex;
  gap: 15px;
  margin: 20px 0;
  justify-content: end;
}

.price-tabs .nav-link {
  color: #ffffff;
  display: inline-block;
  cursor: pointer;
  padding: 8px 20px;
  border: 1px solid #ffffff;
  border-radius: 10px;
  background: #0A0C33;
}

.price-tabs .nav-link:before {
  content: "";
  width: 8px;
  height: 8px;
  border: 1px solid #ffffff;
  display: inline-block;
  margin-right: 15px;
  border-radius: 50%;
  vertical-align: middle;
  outline: 1px solid #ffffff;
  outline-offset: 4px;
  font-weight: 700;
}

.price-tabs .nav-link.active {
  border-color: #0084FF;

  color: #0084FF;
}

.price-tabs .nav-link.active:before {
  border-color: #0084FF;
  outline-color: #0084FF;
}

.pricing-sec.psec-inner .pricing-box {
  background: rgb(5 7 56 / 80%);
}

.login-signup-wrap {
  background: linear-gradient(180deg, #35126A 3.5%, #170C42 100%, #250ACB 100%);
  padding: 120px 0;
}

.login-signup-wrap .row {
  min-height: 100vh;
}

.form-items {
  background: #0A0C33;
  padding: 50px 175px;
}

.form-items .form-control,
.form-items .form-select {
  background-color: #1E204C;
  border-color: #393FAC;
  color: #ffffff;
  font-size: 16px;
  font-family: "Sora", sans-serif;
  height: auto;
  color-scheme: dark;
  padding: 16px 25px;
  font-weight: 300;
}

.form-items :placeholder,
.form-items textarea:placeholder {
  color: #ffffff;
}

.form-items ::placeholder,
.form-items textarea::placeholder {
  color: #ffffff;
}

.form-items .form-title {
  font-family: var(--Sora);
  font-size: 25px;
  font-weight: 600;
  line-height: 31.5px;
  text-align: left;
  color: #ffffff;
}

.form-items .input-group-prepend .form-select {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/></svg>");
  padding-left: 15px;
}

.form-items .input-group .form-control {
  margin-left: -1px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.form-items label {
  color: #ffffff;
  font-family: var(--Sora);
  font-size: 18px;
  font-weight: 300;
  line-height: 22.68px;
  text-align: left;
  margin-bottom: 5px;
}

.form-items .form-control:focus,
.form-items .form-select:focus {
  outline: none;
  box-shadow: none;
}

.form-items .form-logo {
  text-align: center;
  margin-bottom: 40px;
}

.form-info-txt {
  text-align: center;
  color: #ffffff;
  margin-top: 20px;
}

.form-info-txt p {
  color: #ffffff;
  font-family: var(--Sora);
  font-size: 16px;
  font-weight: 300;
  line-height: 20.16px;
  text-align: left;
}

.login-option {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #ffffff;
  margin: 20px 0;
  font-weight: 300;
  font-size: 14px;
}

.login-option:before,
.login-option:after {
  content: "";
  height: 1px;
  background-color: #6265AB;
  display: block;
  flex: 1 1 auto;
}

.login_by_google {

  background: #26285C;

  padding: 12px 15px;

  border-radius: 8px;

  text-align: center;

  display: flex;

  justify-content: center;

  align-items: center;

  gap: 10px;

  color: #ffffff;

  font-family: var(--Sora);

  font-size: 16px;

  font-weight: 300;

  line-height: 20.16px;

  text-align: left;
}

.login_by_google img {
  width: 26px;
}

.form-check-label {
  font-family: var(--Sora);
  font-size: 16px;
  font-weight: 300;
  line-height: 20.16px;
  text-align: left;
}

.login_by_google:hover {
  background: #30326e;
}

.greater-for-client-sec .sec-head {
  text-align: left;
  margin-bottom: 20px;
}

.greater-for-client-sec p {
  color: #B3B3B3;
  padding-right: 7%;
  line-height: 1.9;
}

.icon-list-1 {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  margin: 30px 0 0;
}

.icon-list-1 span {
  display: flex;
  align-items: center;
  font-family: var(--Sora);
  font-size: 18px;
  font-weight: 600;
  line-height: 22.68px;
  text-align: left;
  color: #ffffff;
  gap: 10px;
  background: #15184B;
  padding: 10px 15px;
  border-radius: 5px;
}

.icon-list-1 li {
  min-width: 42%;
}

.white-labeling-sec {}

.white-labeling-sec .sec-head {
  text-align: left;
  margin-bottom: 20px;
}

.white-labeling-sec p {
  color: #B3B3B3;
  padding-right: 7%;
}

.icon-list-2 {

  display: flex;

  /* justify-content: space-between; */

  margin-top: 40px;

  gap: 12%;
}

.icon-list-2 li {
  text-align: center;
}

.icon-list-2 li span {
  width: 50px;
  background: #feb700;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.icon-list-2 li {
  text-align: center;
}

.icon-list-2 li span {
  width: 80px;
  background: #feb700;
  height: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.icon-list-2 li p {
  padding: 0;
  color: #ffffff;
  margin: 15px 0 0;
}





.icon-list-3 {

  display: flex;

  /* justify-content: space-between; */

  margin-top: 40px;

  gap: 20px;

}

.icon-list-3 li {
  text-align: center;
}

.icon-list-3 li {
  text-align: center;
  background: #15184B;
  padding: 24px 20px;
  border-radius: 5px;
}

.icon-list-3 li span {
  width: 80px;
  height: 80px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.icon-list-3 li p {
  padding: 0;
  color: #ffffff;
  margin: 15px 0 0;
  font-size: 18px;
  font-weight: 600;
  font-family: var(--Sora);
}

.faq-sec .cus-accordion-item button {
  background: #070935;
  padding: 15px;
}

.faq-sec .cus-accordion-item {
  margin-bottom: 15px;
  border-style: none;
}

.faq-sec .cus-accordion-item span.icon {
  right: 15px;
}

.faq-sec {
  position: relative;
  z-index: 1;
}

.faq-sec .cus-accordion-content .inner-accordion-content {
  background: #070935;
  padding: 0 20px 10px;
}

.contact-sec {

  position: relative;

  z-index: 1;
}

.contact-form {
  background: rgba(3, 5, 47, 0.55);
  padding: 60px 70px;

}

.contact-form .input-group .input-group-text {
  background: transparent;
  box-shadow: none;
  border-style: none;
  color: #ffffff;
  position: absolute;
  left: 8px;
  top: 16px;
  z-index: 9;
}

.contact-form.form-items .input-group .form-control {
  padding-left: 45px;
}

.list-style-squre {
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-style-squre li {
  position: relative;
  padding-left: 15px;
}

.list-style-squre li:before {
  content: "";
  width: 5px;
  height: 5px;
  background: #ffffff;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 12px;
}

.list-style-squre li {
  color: #ffffff;
  font-size: 16px;
  font-family: var(--sora);
  margin-bottom: 15px;
}

.white-labeling-sec .list-style-squre {
  padding-right: 16%;
}

.white-labeling-sec .img-holder {
  max-width: 95%;
}

.features-list .col {
  flex: 0 0 20%;
  max-width: 20%;
}

.feature-card {
  text-align: center;
  padding: 35px 10px;
  background: #1A1E75;
  border-radius: 10px;
}

.feature-card h2 {
  color: #ffffff;
  font-size: 20px;
  font-weight: 600;
  font-family: var(--Sora);
  margin: 20px 0 10px;
}

.feature-card p {
  color: #B3B3B3;
  font-size: 18px;
  font-weight: 300;
}

.marketplace-sec p {
  color: #B3B3B3;
  font-size: 18px;
  font-family: var(--Sora);
}

.marketplace-sec .img-holder img {
  max-width: 95%;
}
.marketplace-sec .img-holder {
  text-align: right;
}
.deploystrategy-sec .list-style-squre li {
  color: #B3B3B3;
}
.deploystrategy-sec .list-style-squre li::before {
  background-color: #B3B3B3;
}

.deploystrategy-sec .img-holder img {
  max-width: 95%;
}
.deploystrategy-sec .img-holder {
  text-align: right;
}

.tradeguru-sec .img-holder img {
  max-width: 95%;
}
.tradeguru-sec .img-holder {
  text-align: right;
}
.guru-card {
  position: relative;
  background: #272A69;
  border-radius: 5px;
  overflow: hidden;
}
.guru-card .gc-img img {
  width: 100%;
}
.guru-card .gc-img img {
  width: 100%;
}
.guru-card .gc-content {
  padding: 15px 18px;
}

.guru-card .gc-content h2 {
  font-size: 16px;
  color: #FFFFFF;
  margin: 0 0 6px;
}

.guru-card .gc-content p.gc-position {
  margin: 0 0 10px;
  font-family: var(--Sora);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

.guru-card .gc-content p {
  font-size: 16px;
  font-weight: 300;
  color: #B3B3B3;
  margin: 0 0 0;
}
.guru-card .gc-content {
  padding: 15px 13px;
}

.guru-card .gc-content h2 {
  font-size: 16px;
  color: #FFFFFF;
  margin: 0 0 6px;
}

.guru-card .gc-content p.gc-position {
  margin: 0 0 10px;
  font-family: var(--Sora);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

.guru-card .gc-content p {
  font-size: 16px;
  font-weight: 300;
  color: #B3B3B3;
  margin: 0 0 0;
}

.gurus-slider .owl-nav {
  text-align: center;
  margin-top: 25px;
}

body .gurus-slider .owl-nav .owl-prev, 
body .gurus-slider .owl-nav .owl-next {
  padding: 5px 13px !important;
  display: inline-block;
  background: #0085ff;
  color: #ffffff;
  margin: 0 6px;
  border-radius: 4px;
}
body .gurus-slider .owl-nav .owl-prev:hover,
body .gurus-slider .owl-nav .owl-next:hover {
  background-color: #feb700;
}
.guru-card .gc-social-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 15px;
}

.guru-card .gc-social-list a {
  background: #feb700;
  width: 28px;
  text-align: center;
  line-height: 28px;
  height: 28px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.why-partner-with-hft-sec .img-holder img {
  max-width: 95%;
}
.sec.dark-bg1.tradeguru-list-sec {
  background: #101243;
}
.terms-and-conditions-sec .list-style-squre li {
  color: inherit;
  margin-bottom: 5px;
}
.error-sec {
  text-align: center;
}